import { useState } from 'react';

type ValidityStateKey = keyof ValidityState;
type ErrorMessage = Omit<Record<ValidityStateKey, string>, 'customError'> & {
  customError?: string;
};
type FormElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

export type WithValidation<
  T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>,
> = React.ComponentPropsWithRef<T> & {
  errorMessages?: Partial<ErrorMessage>;
  label?: React.ReactNode;
  rightAddon?: React.ReactNode;
  onErrorMessage?: (error?: string) => React.ReactNode;
};

const getDefaultErrorMessages = <T extends FormElement>(e: React.FormEvent<T>): ErrorMessage => {
  let max = 'The max value';
  let min = 'The min value';
  const target = e.currentTarget || e.target;

  if (target instanceof HTMLInputElement) {
    max = target.max;
    min = target.min;
  }

  return {
    badInput: 'Le champ est incorrect',
    patternMismatch: 'Le champ est incorrect',
    rangeOverflow: `Doit être inférieur à ${max}`,
    rangeUnderflow: `Doit être supérieur à ${min}`,
    stepMismatch: 'Le champ est incorrect',
    tooLong: 'Le texte est trop long',
    tooShort: 'Le texte est trop court',
    typeMismatch: 'Le champ est incorrect',
    valueMissing: 'Le champ est requis',
    // customError: '__unused__',
    valid: '__unused__',
  };
};

const getErrorMessage = <T extends FormElement>(
  e: React.FormEvent<T>,
  errorMessages: Partial<ErrorMessage> = {},
) => {
  // @ts-expect-error event type mismatch
  const validityState = e.currentTarget.validity || e.target.validity;
  let currentVSK: ValidityStateKey | undefined;
  let vSK: ValidityStateKey;

  for (vSK in validityState) {
    if (
      validityState[vSK] &&
      !['valid', 'customError' in errorMessages ? undefined : 'customError']
        .filter(Boolean)
        .includes(vSK)
    ) {
      currentVSK = vSK;
    }
  }

  const messages: ErrorMessage = {
    ...getDefaultErrorMessages(e),
    ...errorMessages,
  };

  return currentVSK ? messages[currentVSK] : '';
};

export const createValidationHandler =
  <T extends FormElement>(
    onError: React.Dispatch<React.SetStateAction<string | null | undefined>>,
    errorMessages: Partial<ErrorMessage> = {},
  ) =>
  (e: React.FormEvent<T>) => {
    e.preventDefault();

    const error = getErrorMessage(e, errorMessages);
    const target = e.currentTarget || e.target;

    if (error) {
      if ('setCustomValidity' in target) {
        target.setCustomValidity(error);
      }
      onError(error);
    } else {
      if ('setCustomValidity' in target) {
        target.setCustomValidity('');
      }
      onError(null);
    }
  };

export const useFieldValidation = (errorMessages?: Partial<ErrorMessage>) => {
  const [error, setError] = useState<string | null | undefined>(null);

  return {
    props: {
      onInvalid: createValidationHandler(setError, errorMessages),
      onInput: createValidationHandler(setError, errorMessages),
    },
    error,
  };
};

export const EMAIL_REGEX = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,6}';
export const PASSWORD_REGEX = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$';
